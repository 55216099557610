<template>
  <!-- Prices -->
    <div style="width: 100%;">
        <b-overlay :show="tableOverlay" rounded="sm">
            <b-card>
                <div class="">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Nombre de catalogo</th>
                            <th>Nombre identificador</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(companyProductCatalog, index) in companyProductsCatalog" :key="companyProductCatalog.companyProductCatalogId">
                            <td>
                                <v-select
                                    :disabled="!!companyProductCatalog.companyProductCatalogId"
                                    label="name"
                                    v-model="companyProductCatalog.productId"
                                    :options="products"
                                    :reduce="option => option.productId"
                                    :clearable="false"
                                    @input="product => updateCompanyProductNameOnSelect(index, product)"
                                    class="select-size-m"
                                />
                            </td>
                            <td>
                                <b-form-input
                                    v-model="companyProductCatalog.name"
                                />
                            </td>
                            <td>
                                <b-button
                                    v-if="false"
                                    variant="outline-danger"
                                    class="mt-0 mt-md-2"
                                    @click="removeItem(index)"
                                >
                                    <feather-icon
                                        icon="XIcon"
                                        class="mr-25"
                                    />
                                    <span>Eliminar</span>
                                </b-button>
                            </td>
                        </tr>
                        <tr>
                            <b-button
                                variant="outline-primary"
                                @click="addNewItem()"
                            >
                                <feather-icon
                                    icon="PlusIcon"
                                    class="mr-25"
                                />
                                <span>Agregar un item</span>
                            </b-button>
                        </tr>
                        </tbody>
                    </table>
                    <div v-if="companyProductsCatalog.length === 0" class="mt-2" style="text-align: center">
                        <h6 class="mb-2">No hay registros.</h6>
                    </div>
                </div>
                <div class="mt-2" style="text-align: center">
                    <b-button variant="primary" @click="changeCompanyProductsCatalogApi()">
                        <feather-icon icon="SaveIcon" class="mr-50" />
                        Guardar cambios
                    </b-button>
                </div>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {BCard, BFormInput, BButton, BOverlay, BTab, BTabs, BFormGroup} from 'bootstrap-vue';
import utils from '@/utils/utils.js';
import vSelect from "vue-select";

export default {
  components: {
      BFormGroup,
      BTabs, BTab,
    BCard,
    BFormInput,
    BButton,
    BOverlay,
      vSelect,
  },
  data() {
    return {
      // Data
      user: JSON.parse(localStorage.getItem("user")),
      products: [],
      companyProductsCatalog: [],

      // Props
      tableOverlay: true,
    };
  },
  created() {
    this.findProductsApi()
  },
  methods: {
  findProductsApi() {
      this.$axios
          .post(this.$services + 'manager/findProductsApi', {})
          .then((res) => {
              if (res.data) {
                  this.products = res.data;
              }
          }).finally(()=>{
              this.findCompanyProductsCatalogApi()
          })
    },
    findCompanyProductsCatalogApi() {
      this.$axios
          .post(this.$services + 'manager/findCompanyProductsCatalogApi', {
              companyId: this.user.companyId,
          })
          .then((res) => {
              if (res.data) {
                  this.companyProductsCatalog = res.data;
              }
          })
          .finally(() => {
              this.tableOverlay = false;
          });
    },
    changeCompanyProductsCatalogApi() {
        this.tableOverlay = true;

        this.$axios
            .post(this.$services + 'manager/changeCompanyProductsCatalogApi', {
                companyProductsCatalog: this.companyProductsCatalog
            })
            .then((res) => {
                this.showToast('success', 'Lista de productos actualizados');
            })
            .finally(() => {
                this.tableOverlay = false;
            });
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      });
    },
    addNewItem() {
        this.companyProductsCatalog.push({
            productId: this.products[0].productId,
            companyId: this.user.companyId,
            name: this.products[0].name,
        });
    },
    removeItem(index) {
      return
        const priceListProductId = priceListProducts[index].priceListProductId
        if (priceListProductId) {
            this.priceListProductIdsToRemove.push(priceListProductId)
        }
        priceListProducts.splice(index, 1);
    },
    removePriceList(priceListId, index) {
      if (priceListId) {
          this.removePriceListApi(priceListId)
      }
      this.priceLists.splice(index, 1);
    },
    removePriceListApi(priceListId) {
      this.$axios
          .post(this.$services + 'manager/removePriceListApi', {
              priceListId
          })
          .then((res) => {
              this.showToast('success', 'Lista eliminada');
          })
          .finally(() => {
              this.findPriceListsApi();
              this.tableOverlay = false;
      });
    },
    updateCompanyProductNameOnSelect(index, productId){
      const product = this.products.find(product => product.productId === productId)
      this.companyProductsCatalog[index].name = product.name
    }
  },
};
</script>
